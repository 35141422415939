.formMainDiv {
  width: 60%;
  margin: auto;
  background: rgba(217, 217, 217, 0.1);
  border-radius: 1.25rem;
  padding: 3rem 2rem;
  margin-top: 8rem;
  /* border: 2px solid red; */
}

/* Heading  */
.heading {
  color: #8E8BD5;
  text-align: center;
  font-family: var(--lh-clash);
  font-size: 2.625rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

/* Paragraph  */
.para {
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 80%;
  margin: auto;
  margin-top: 1rem;
}

/* Form Div  */
.formDiv {
  /* border: 2px solid red; */
  margin-top: 5rem;
}

/* name-email-box  Starts*/
.nameEmailBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2.5rem;
  margin-top: 2.5rem;
}

.label {
  color: #fff;
  /* text-align: center; */
  font-family: Poppins;
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.name {
  border-radius: 0.625rem;
  background: #010101;
  height: 3rem;
  border: none;
  /* width: 100%; */
  font-family: Poppins;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: #d1d1d1;
  padding-left: 1rem;
  /* flex-shrink: 0; */
}

.name::placeholder {
  /* color: #D1D1D1;
    /* text-align: center; */
  padding-left: 1rem;
}

.nameDiv {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  flex: 1;
}

/* name-email-box ENDS */

/* NumCountryBox Starts  */
.numCountryBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2.5rem;
  margin-top: 2.5rem;
}

.select {
  border-radius: 0.625rem;
  background: #010101;
  height: 3rem;
  color: #d1d1d1;
  font-family: Poppins;
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: none;
}

.select option {
  color: #d1d1d1;
}

/* NumCountryBox Ends  */

/* expBudgetBox Starts  */
.expBudgetBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2.5rem;
  margin-top: 2.5rem;
}

.expDiv {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  flex: 1;
}

.expDiv p {
  color: #d1d1d1;
  font-family: Poppins;
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.budgetDiv p {
  color: #d1d1d1;
  font-family: Poppins;
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.budgetDiv {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  flex: 1;
}

/* expBudgetBox Ends  */

/*Deadline Website Link Starts */
.deadlineLinkbox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2.5rem;
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.deadlineDiv {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  flex: 1;
}

.deadlineDiv p {
  color: #d1d1d1;
  font-family: Poppins;
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.deadlineInput {
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 100%;
}

.deadlineInput input {
  border-radius: 0.625rem;
  width: 100%;
  background: #010101;
  height: 3rem;
  border: none;
  /* width: 100%; */
  font-family: Poppins;
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: #d1d1d1;
  padding-left: 1rem;
}

.websiteDiv {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  flex: 1;
}

.details {
  border-radius: 0.625rem;
  background: #010101;
  height: 3rem;
  border: none;
  /* width: 100%; */
  font-family: Poppins;
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: #d1d1d1;
  padding-left: 1rem;
  /* flex-shrink: 0; */
}

.details::placeholder {
  /* color: #D1D1D1;
    /* text-align: center; */
  padding-left: 1rem;
}

/* Deadline Website Link Ends  */

/* Website Main Div Starts */
.websiteMainDiv {
  margin-top: 2.5rem;
}

.websiteDiv textarea {
  border-radius: 0.625rem;
  background: #010101;
  color: #d1d1d1;
  font-family: Poppins;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 100%;
  height: 5rem;
  margin-top: 0.5rem;
  border: none;
  resize: none;
}

.websiteDiv p {
  color: #fff;
  font-family: Poppins;
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.websiteDiv textarea::placeholder {
  padding-left: 1rem;
  padding-top: 0.75rem;
}

/* Website Main Div Ends */

/* mess Main Div Starts  */
.messMainDiv {
  margin-top: 2.5rem;
}

.messDiv p {
  color: #fff;
  font-family: Poppins;
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.messDiv textarea {
  border-radius: 0.625rem;
  background: #010101;
  color: #d1d1d1;
  font-family: Poppins;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 100%;
  height: 5rem;
  margin-top: 0.5rem;
  border: none;
  resize: none;
}

.messDiv textarea::placeholder {
  padding-left: 1rem;
  padding-top: 0.75rem;
}

/* mess Main Div Ends  */

.submitForm {
  padding-top: 2.5rem;
  /* border: 2px solid red; */
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.inputBtn {
  border-radius: 0.625rem;
  background: #8E8BD5;
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  height: 4rem;
  padding: 0px 2rem;
  border: none;
  cursor: pointer;
}

.select {
  width: 100%;
  padding: 0 1rem;
}

.select option {
  font-size: 0.9rem;
}

@media (max-width: 1024px) {
  .formMainDiv {
    width: 80%;
  }
}

@media (max-width: 768px) {
  .heading {
    font-size: 1.8rem;
  }

  .para {
    font-size: 1rem;
  }

  .nameEmailBox,
  .numCountryBox,
  .expBudgetBox,
  .deadlineLinkbox {
    flex-direction: column;
    /* border: 2px solid white; */
    align-items: unset;
  }

  .nameDiv {
    flex: unset;
    /* border: 2px solid red; */
  }

  .deadlineInput {
    flex-direction: column;
  }

  .label {
    font-size: 1.1rem;
  }

  .websiteDiv p {
    font-size: 1rem;
  }

  .formMainDiv input,
  .formMainDiv .select {
    height: 3.5rem;
    font-size: 1rem;
  }
}

@media (max-width: 475px) {
  .formMainDiv {
    width: 90%;
    padding: 2rem 1rem;
  }

  .label {
    font-size: 1rem;
  }

  .inputBtn {
    padding: 0 1rem;
  }
}

@media (max-width: 320px) {
  .inputBtn {
    padding: 0 0.2rem;
    font-size: 0.8rem;
  }
}