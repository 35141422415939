@import url("https://fonts.cdnfonts.com/css/montserrat");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap");
@import url("https://fonts.cdnfonts.com/css/nunito-sans");

html,
body,
#root {}

*,
#root,
html {
  scrollbar-width: thin;
  scrollbar-color: #8E8BD5 #2b2b2b;
  scroll-behavior: smooth;
}

.css-1lfjpnz-MuiContainer-root {}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Montserrat", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000000;
  --landing-bg: url("./assets/background-lines.png");
  --secondary-bg: url("./assets/linebg.png");
  --primary-font-color: #ffffff;
  --invert-font-color: #000000;
  --secondary-font-color: #ffffffcc;
  --border-font-fill: transparent;
  --border-font-stroke: #8E8BD5;
  --nav-bg: linear-gradient(to bottom, #000000ff, #00000044);
  --card-bg-transparent: #3c3c3c80;
  --modal-bg: #151515e9;
  --card-bg-non-transparent: #3c3c3ca6;
  --team-btn-bg: #8E8BD5;
  --img-filter-light: none;
  --img-filter-full: none;
  --img-darken-filter: brightness(0.5) contrast(1.6) saturate(0.4);
  --circle-bg: url("./assets/background-circle-design.png");
  --div-border: #ffffff80;
}

body.light {
  background-color: #f5f5f5;
  --landing-bg: linear-gradient(89.6deg,
      rgba(222, 235, 255, 0.76) 1.82%,
      rgba(184, 198, 219, 0.65) 99.55%);
  --secondary-bg: linear-gradient(89.6deg,
      rgba(222, 235, 255, 0.76) 1.82%,
      rgba(184, 198, 219, 0.65) 99.55%);
  --primary-font-color: #000000;
  --invert-font-color: #ffffff;
  --secondary-font-color: #000000cc;
  --border-font-fill: #8E8BD5;
  --border-font-stroke: transparent;
  --nav-bg: linear-gradient(to bottom,
      rgba(222, 235, 255, 0.76) 1.82%,
      rgba(184, 198, 219, 0.65) 99.55%);
  --card-bg-non-transparent: #e8e8e8;
  --card-bg-transparent: #e8e8e8;
  --modal-bg: #e8e8e8;
  --team-btn-bg: #585858;
  --img-filter-light: invert(50%);
  --img-filter-full: invert(100%);
  --img-darken-filter: none;
  --circle-bg: none;
  --div-border: #00000080;
}

a {
  text-decoration: none;
}

button {
  color: var(--invert-font-color) !important;
}

body::-webkit-scrollbar {
  width: 10px;
}

body::-webkit-scrollbar-track {
  background: #2b2b2b;
}

body::-webkit-scrollbar-thumb {
  background: #8E8BD5;
  border-radius: 3px;
}

#txt-rotate>.wrap {
  border-right: 0.08em solid #666;
}