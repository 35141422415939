.mainTitle{
    font-family: 'Phenomicon Laser';
    font-style: normal;
    font-weight: 400;
    font-size: 128px;
    line-height: 127px;

    text-align: center;

    background: linear-gradient(90.06deg, #386442 1.07%, #8EC367 99.95%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

.contentWrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2% 8% 8% 8%;
}

.mainTitleDiv{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5em 1em 7em 1em;
    gap: 1em;
}

.mainTitleDiv p{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    max-width: 80%;
    text-align: center;
}

.noticeDesc{
    background: rgba(255, 255, 255, 0.11);
    box-shadow: 0px 16px 40px rgb(0 0 0 / 15%);
    border-radius: 40px;
    padding: 35px 45px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 30px;
    text-align: left;
    max-width: 1110px;
}

.effeDate{
    width: 100%;
    text-align: left;
    padding: 35px 45px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 15px;
    max-width: 1110px;
}

.titles{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 70px;

    text-transform: uppercase;

    background: linear-gradient(90.06deg, #386442 1.07%, #8EC367 99.95%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

.contentDiv{
    display: flex;
    flex-direction: column;
    gap: 3em;
    max-width: 1110px;
    width: 100%;
}

.contact{
    display: flex;
    flex-direction: column;
    gap: 20px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 25px;
}

.contactDesc{
    display: flex;
    gap: 20px;
}

.contactPhoneIcon{
    width: 20px;
}

.contactEmailIcon{
    width: 20px;
}

.contactLocationIcon{
    width: 20px;
    align-self: baseline;
    scale: 2;
}


.contactEmail{
    text-decoration-line: underline;
}

.locationTxt{
    font-size: 20px;
}

.policyTnc{
    display: flex;
    flex-direction: column;
    padding: 6em 0;
    gap: 4em;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 30px;
}

.policyTncContent{
    display: flex;
    flex-direction: column;
    gap: 3em;
    max-width: 1110px;
}

.descWrapper{
    display: flex;
    flex-direction: column;
}

ol li{
    margin: 20px 0;
}


@media only screen and (max-width: 700px) {
    .mainTitle{
        font-size: 4rem;
        line-height: 70px;
    }
    .mainTitleDiv{
        padding: 4em 1em 4em 1em;
    }
    .mainTitleDiv p{
        font-size: 13px;
    }
    .notice-desc{
        font-size: 11px;
        line-height: 24px;
    }
    .effe-date{
        font-size: 12px;
        line-height: 10px;
        text-align: center;
    }
    .titles{
        font-size: 35px;
        line-height: 35px;
    }
    .policyTnc{
        padding: 2em 0;
    }
}