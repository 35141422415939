html {
  scroll-behavior: smooth;
}

.mainFooter {
  display: flex;
  justify-content: space-between;
  --max-width: 1680px;
  --container-padding: 1rem;
  width: min(var(--max-width), 100% - (var(--container-padding) * 2));
  margin-inline: auto;
  padding: 3em 4em;
}

.title {
  display: flex;
  gap: 10px;
  align-items: center;
  color: white;
}

.socialIconDiv {
  display: flex;
  gap: 10px;
  transition: all 0.3s ease-in-out;
}

.socialIconDiv img {
  cursor: pointer;
  width: 20px;
  height: 20px;
}

.signupWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-self: center;
}

.signupInput {
  overflow: hidden;
  display: flex;
  align-items: center;
  border-radius: 10px;
}

.signupInput img {
  background: #8E8BD5;
  width: 15px;
  height: 15px;
}

.signupInput input {
  height: 100%;
  border: none;
  height: 47px;
  background: #1f1f1f;
  color: white;
  padding: 16px;
  line-height: 10px;
  font-size: 15px;
}

.signupInput button {
  border: none;
  padding: 16px 14px;
  background: #8E8BD5;
}

.signupTxt {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
}

.highlighted {
  letter-spacing: 0.1em;
  text-transform: uppercase;

  color: #8E8BD5;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  padding-bottom: 5px;
  padding-top: 5px;
}

.webpageLinks {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: flex-end;
  align-items: center;
  column-gap: 50px;
  row-gap: 10px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  cursor: pointer;
}

.desc {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 25px;
  color: rgba(255, 255, 255, 0.74);
}

.title p {
  font-family: 'Orbitron';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 22px;
  letter-spacing: 0.1em;
}

.titleDesc {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 15px;
  letter-spacing: 0.1em;
  color: rgba(255, 255, 255, 0.71);
  max-width: 320px;
  margin-top: 10px;
  text-align: left;
}

.socials1 {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.socials2 {
  display: none;
  flex-direction: column;
  gap: 10px;
}

.footerLogoDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
}

.webpageLinksWrapper {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 800px) {
  .mainFooter {
    flex-direction: column;
    align-items: center;
    gap: 2em;
  }

  .title {
    display: flex;
    gap: 10px;
    align-items: center;
    color: white;
    justify-content: center;
  }

  .titleDesc {
    text-align: center;
    font-size: 10px;
    line-height: 15px;
    letter-spacing: 0.1em;
    width: 90%;
    margin-top: 10px;
  }

  .webpageLinksWrapper {
    display: flex;
    gap: 1em;
    width: 80%;
    justify-content: space-evenly;
  }

  .socials1 {
    display: none;
  }

  .socials2 {
    display: flex;
  }

  .socialIconDiv img {
    cursor: pointer;
    width: 15px;
    height: 15px;
  }

  .mainFooter {
    padding: 2em 0.5em;
  }

  .socials2 {
    font-size: 20px;
    justify-content: center;
    text-align: center;
  }

  .socialIconDiv a img {
    width: 30px;
    height: auto;
  }
}