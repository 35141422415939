.accordionContainer {
  margin-top: 10rem;
  margin-bottom: 8rem;
}

.accordianHeading {
  color: var(--fc-white);
  text-align: center;
  font-family: var(--fc-clashVar);
  font-size: 2.75rem;
  font-weight: 600;
  line-height: 2.8125rem;
  /* 102.273% */
}

.accordionMain {
  width: 60%;
  margin: auto;
  margin-top: 4rem;
}

.accordion {
  border-radius: 0.75rem;
  border: 2px solid var(--fc-grayStroke);
  background: var(--fc-blackBackground);
  margin-bottom: 10px;
  overflow: hidden;
  padding: 2.1875rem 2rem;
  height: auto;
  max-height: 6.125rem;
  transition: max-height 400ms ease-in-out !important;
}

.accordion.accordionOpen {
  border: 2px solid #8E8BD5 !important;
  height: auto;
  max-height: 20rem !important;
}

.accordion:hover {
  border: 2px solid var(--fc-white);
  transition: all 0.3s ease-in-out !important;
}

.accordionHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  gap: 1rem;
}

.accordionHeader h2 {
  color: var(--fc-white);
  font-family: var(--fc-outFit);
  font-size: 1.375rem;
  font-weight: 600;
  width: 100%;
}

.accordionContent p {
  color: var(--fc-testiStroke);
  text-align: justify;
  font-family: var(--fc-outFit);
  font-size: 1rem;
  font-weight: 400;
  line-height: 130.523%;
  /* 1.30525rem */
  letter-spacing: -0.03rem;
  padding-top: 2rem;
}

.accordionHeader>svg {
  color: var(--fc-white);
  transition: transform 0.3s ease-in-out;
}

.icon {
  transform: rotate(45deg);
}

@media (max-width: 1024px) {
  .accordionMain {
    width: 80%;
  }
}

@media (max-width: 768px) {
  .accordianHeading {
    font-size: 2.2rem;
  }

  .accordionMain {
    width: 90%;
  }
}

@media (max-width: 588px) {
  .accordionContainer {
    margin-top: 6rem;
  }

  .accordianHeading {
    font-size: 2rem;
  }

  .accordionMain {
    width: 95%;
  }

  .accordion {
    padding: 2rem 1rem;
  }

  .accordionHeader>h2,
  .accordionHeader>svg {
    font-size: 1rem;
  }
}