.testimonial {
  margin-top: 10rem;
}

.testimonialHeaderwrapper {
  width: 90%;
  margin: auto;
  position: relative;
}

.testimonialHeader {
  position: absolute;
  bottom: -2rem;
  left: 6rem;
}

.testimonialHeading {
  color: var(--fc-white);
  font-family: var(--fc-clashVar);
  font-size: 2.75rem;
  font-weight: 600;
}

.testimonialHeaderDesc {
  color: var(--fc-white);
  font-family: var(--fc-outFit);
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.75rem; /* 155.556% */
}

.testimonialContentWrapper {
  width: 90%;
  margin: auto;
  margin-top: 8.5rem;
}

.testimonialContent {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
}

.testimonialItem {
  border-radius: 0.5rem;
  border: 1px solid var(--fc-testiStroke);
  background: var(--fc-blackBackground);
  display: flex;
  padding: 2rem;
  gap: 1rem;
  box-sizing: border-box;
}

.testimonialSmallquote > svg {
  width: 1rem;
  height: 1rem;
  /* margin-top: -1rem; */
}

.testimonialItemData {
  width: 100%;
}

.testimonialDesc {
  color: var(--fc-white);
  font-family: var(--fc-outFit);
  font-size: 1.125rem;
  font-weight: 400;
}
.testimonialProfileWrapper {
  margin-top: 1.5rem;
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.testimonialName {
  color: var(--fc-white);
  font-family: var(--fc-clash);
  font-size: 1.125rem;
  font-weight: 600;
  letter-spacing: 0.1rem;
  line-height: 1.75rem; /* 155.556% */
}

.testimonialPosition {
  color: var(--fc-gray);
  font-family: var(--fc-outFit);
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem; /* 142.857% */
}

.testItem1 {
  width: 24rem;
}

.testItem2 {
  width: 30.5rem;
}

.testItem3 {
  width: 24rem;
}

.testimonialSecondContent {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

/* profilePic  */

.testimonialProfile {
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 3.5rem;
}

.testimonialProfile > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 3.5rem;
}

@media (max-width: 996px) {
  .testimonialItem {
    padding: 1rem;
    gap: 0.5rem;
  }
  .testItem1 {
    width: 100%;
  }

  .testItem2 {
    width: 100%;
  }

  .testItem3 {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .testimonialDesc {
    font-size: 1rem;
  }
}

@media (max-width: 650px) {
  .testimonialContent {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.75rem;
    flex-direction: column;
  }
}

@media (max-width: 425px) {
  .testimonialHeader {
    bottom: -2rem;
    left: 10%;
  }
}

@media (max-width: 375px) {
  .testimonialHeader {
    bottom: -2rem;
    left: 0%;
  }
}
