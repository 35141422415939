.authority {
  margin-top: 10rem;
}

.authorityHeading {
  color: var(--fc-white);
  text-align: center;
  font-family: var(--fc-clashVar);
  font-size: 2.75rem;
  font-weight: 600;
}

.authorityWrapper {
  width: 90%;
  margin: auto;
  margin-top: 3.75rem;
  display: grid;
  align-items: center;
  grid-template-columns: repeat(2, 1fr);
  gap: 4.38rem;
}

.authorityDesc {
  color: var(--fc-textDesc);
  font-family: var(--fc-outFit);
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 145.5%; /* 2.1825rem */
  letter-spacing: -0.03rem;
}

@media (max-width: 958px) {
  .authorityWrapper {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (max-width: 768px) {
  .authorityHeading {
    font-size: 2.25rem;
  }

  .authorityDesc {
    font-size: 1.1rem;
  }
}

@media (max-width: 558px) {
  .authority {
    margin-top: 6rem;
  }
  .authorityHeading {
    font-size: 2rem;
  }
}
