.container {
    background-image: url('../../assets/ball1.png'),
        url('../../assets//ball2.png'), url('../../assets/bg_gradient.png');
    display: flex;
    justify-content: center;
    padding: 70px 0px;
    background-position: top right, left bottom, center center;
    background-repeat: no-repeat, no-repeat, no-repeat;
}

.box1,
.box2 {
    width: 42%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.box1 {
    padding: 79px 65px;
    background: linear-gradient(180deg, #002339 -19.53%, #1d0b1d 122.66%);
    border-radius: 20px 0px 0px 20px;
    gap: 100px;
}

.box1>div:nth-child(1)>h1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 72px;
    color: #ffffff;
}

.box1>div:nth-child(1)>p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    color: rgba(255, 255, 255, 0.62);
}

.box1>div:nth-child(2)>p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: rgba(255, 255, 255, 0.62);
}

.box1>div:nth-child(2) {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.box2 {
    padding: 26px 47px;
    background: #f8f8f8;
    border-radius: 0px 20px 20px 0px;
    gap: 20px;
    position: relative;
}

.box2::before {
    content: '';
    position: absolute;
    height: 100%;
    width: 250px;
    left: -11rem;
    bottom: -12rem;
    background-image: url("../../assets/images/contactUsMascot.png");
    background-repeat: no-repeat;
    z-index: 1;
}

@media (max-width: 750px) {
    .box2::before {
        display: none;
    }
}

.media {
    display: flex;
    gap: 20px;
    align-items: center;
}

.location>h3 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: #081c31;
    margin-bottom: 10px;
}

.location>p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #081c31;
}

.location span {
    font-weight: 600;
}

.box2 form {
    display: flex;
    flex-direction: column;
    gap: 13px;
}

.box2 input,
.box2 textarea {
    background: #ededed;
    border: none;
    border-radius: 10px;
    width: 100%;
    height: 64px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: rgba(8, 28, 49, 0.47);
    padding: 21px;
    outline: none;
    resize: none;
}

.box2 button {
    background: #8E8BD5;
    border: none;
    border-radius: 10px;
    padding: 20px 30%;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #ffffff !important;
    cursor: pointer;
}

.box2 textarea {
    height: 109px;
}

@media screen and (max-width: 1000px) {

    .box1,
    .box2 {
        width: 45%;
    }
}

@media screen and (max-width: 800px) {
    .container {
        flex-direction: column;
        align-items: center;
    }

    .box1,
    .box2 {
        width: 80%;
    }

    .box1 {
        border-radius: 20px 20px 0px 0px;
    }

    .box2 {
        border-radius: 0px 0px 20px 20px;
    }
}

@media screen and (max-width: 500px) {

    .box1,
    .box2 {
        width: 90%;
    }

    .box1 {
        padding: 25px 25px;
    }

    .box2 {
        padding: 26px 26px;
    }
}