.whatWeAreContainer {
  padding: 3rem 0rem;
  background: #8E8BD5;
}

.whatWeareInfo {
  margin-left: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  color: #fff;
  text-align: center;
  font-family: var(--lh-jakartaSans);
  font-size: 1.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

@media (max-width: 580px) {
  .whatWeAreContainer {
    padding: 1.5rem 0rem;
  }

  .whatWeareInfo {
    margin-left: 1.5rem;
    font-size: 1.4rem;
  }
}