@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap");

.formContainer {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  scroll-behavior: smooth;
  color: white;
  background: linear-gradient(180deg, #002339 0%, #1d0b1d 100%);
  padding: 0px 20px;
}

.container {
  --max-width: 1500px;
  --container-padding: 1rem;

  width: min(var(--max-width), 100% - (var(--container-padding) * 2));
  margin-inline: auto;
}

.mainuiWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 1000px;
  gap: 20px;
}

.mainuiImg {
  width: 25%;
}

.mainuiDesc {
  text-align: center;
  font-size: 0.9rem;
  margin-top: -1rem;
  width: 80%;
}

.LetsgoBtn {
  background: #8E8BD5;
  border-radius: 10px;
  padding: 10px 60px;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
  cursor: pointer;
}

.LetsgoBtn:hover {
  background: #8E8BD5;
}

.mainuiheading {
  font-family: Poppins;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: center;
}

.countrySelect {
  height: 50px !important;
  width: 25%;
  border: none;
  padding: 0 0.2rem;
  color: #fff;
  background: #1d3852;
  border-radius: 0.5rem;
}

.nameInput,
.countryInput,
.phoneInput {
  display: block;
  width: 100%;
  font-family: inherit;
  color: rgb(235, 235, 235);
  padding: 0px 0px 8px;
  border: none;
  outline: none;
  border-radius: 0px;
  appearance: none;
  background-image: none;
  background-position: initial;
  background-size: initial;
  background-repeat: initial;
  background-attachment: initial;
  background-origin: initial;
  background-clip: initial;
  transform: translateZ(0px);
  font-size: 30px;
  -webkit-font-smoothing: antialiased;
  line-height: unset;
  -webkit-text-fill-color: rgb(235, 235, 235);
  animation: 1ms ease 0s 1 normal none running native-autofill-in;
  transition: background-color 1e8s ease 0s, box-shadow 0.1s ease-out 0s;
  box-shadow: rgb(235 235 235 / 30%) 0px 1px;
  background-color: transparent !important;
  margin-top: 1em;
}

.emailInput {
  display: block;
  width: 100%;
  font-family: inherit;
  color: rgb(235, 235, 235);
  padding: 0px 0px 8px;
  border: none;
  outline: none;
  border-radius: 0px;
  appearance: none;
  background-image: none;
  background-position: initial;
  background-size: initial;
  background-repeat: initial;
  background-attachment: initial;
  background-origin: initial;
  background-clip: initial;
  transform: translateZ(0px);
  font-size: 30px;
  -webkit-font-smoothing: antialiased;
  line-height: unset;
  -webkit-text-fill-color: rgb(235, 235, 235);
  animation: 1ms ease 0s 1 normal none running native-autofill-in;
  transition: background-color 1e8s ease 0s, box-shadow 0.1s ease-out 0s;
  box-shadow: rgb(235 235 235 / 30%) 0px 1px;
  background-color: transparent !important;
  margin-top: 1em;
}

.errorMsg {
  color: #ff0000;
  font-size: 12px;
  margin-top: 5px;
}

.nameInput:focus {
  box-shadow: rgb(235 235 235) 0px 2px;
}

.nameInput::placeholder {
  color: rgba(255, 255, 255, 0.19);
  opacity: 0.19;
}

.emailInput::placeholder {
  color: rgba(255, 255, 255, 0.19);
  opacity: 0.19;
}

.phoneInput::placeholder {
  color: rgba(255, 255, 255, 0.19);
  opacity: 0.19;
}

.nameInput:placeholder-shown {
  color: rgba(255, 255, 255, 0.19);
}

.okBtn {
  background: #8E8BD5;
  border-radius: 5px;
  width: 70px;
  text-align: center;
  padding: 5px 8px;
  cursor: pointer;
  margin-top: 2em;
}

.okBtn:hover {
  background: #8E8BD5;
}

.primaryTxt {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
}

.secondaryTxt {
  color: rgba(255, 255, 255, 0.61);
}

.counterDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  height: fit-content;
  gap: 5px;
}

.formWrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 5px;
}

.questionWrapper {
  max-width: 720px;
  width: 100%;
}

.arrowIcon {
  width: 10px;
}

.congratsWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  width: 100%;
  max-width: 650px;
}

.congratsTxt {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 30px;
  text-align: center;
}

.congratsDesc {
  text-align: center;
  font-size: 0.9rem;
}

.contactUsWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.contactUsTxt {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 35px;
}

.highlightedTxt {
  color: rgba(39, 182, 122, 1);
  font-weight: 700;
}

/* Custom select styling */
.custom-select {
  position: relative;
  height: 55px;
  border-radius: 4px;
  font-size: 16px;
  color: #fff;
  width: 100%;
  margin-top: 30px;
}

/* Select button styling */
.select-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  color: white;
  background: #1d3852;
  box-shadow: 0px 16px 40px rgb(0 0 0 / 15%);
  font-weight: 400;
  font-size: 20px;
  line-height: 35px;
}

.finishButton {
  display: flex;
  align-items: center;
  background: #fff;
  color: #000;
  padding: 10px 40px;
  border-radius: 10px;
  transition: 0.3s all ease-in-out;
  margin-bottom: 5rem;
}

.finishButton:hover {
  background: #8E8BD5;
  color: #fff;
}

.finishButton p {
  color: #000;
  font-weight: 600;
  font-size: 20px;
}

.selectedTxt {
  padding: 10px 15px;
}

.selectedInputTxt {
  outline: none;
  border: none;
  width: 100%;
  border-radius: 10px;
}

/* Arrow icon styling */
.arrow {
  width: 15px;
  transform: translateY(2px);
  transition: transform 0.2s ease-in-out;
}

.arrow.open {
  transform: translateY(-2px) rotate(180deg);
}

/* Options styling */
.options {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
  background-color: #1d3852;
  border-radius: 0 0 10px 10px;
  box-shadow: 0 2px 4px rgb(0 0 0 / 10%);
  z-index: 1;
  transition: opacity 0.2s ease-in-out;
  padding: 1em;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

/* Option styling */
.option {
  height: 35px !important;
  padding: 8px 12px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  color: #fff;
}

.option:hover {
  background-color: #3b6b9c;
}

/* Selected option styling */
.option.selected {
  background-color: #3b6b9c;
}

.selectBtnWrapper {
  background: #527fa9;
  border-radius: 0px 10px 10px 0px;
  height: 55px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.options>div:not(:last-child) {
  border-bottom: solid 1px rgba(255, 255, 255, 0.29);
}

.letsGOBtnWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.letsGOBtnWrapper p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 30px;
  text-align: center;
  color: #ffffff;
}

.letsGOBtnWrapper p span {
  font-weight: bold;
}

.customSelect {
  position: relative;
  height: 55px;
  border-radius: 4px;
  font-size: 16px;
  color: #fff;
  width: 100%;
  margin-top: 30px;
}

.phoneNodiv {
  display: flex;
  gap: 1rem;
  margin-top: 30px;
  align-items: center;
}

.countryInput {
  width: 10% !important;
}

.phoneInput {
  width: 80% !important;
  margin-top: 0;
}

.selectButton {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  color: white;
  background: #1d3852;
  box-shadow: 0px 16px 40px rgb(0 0 0 / 15%);
  font-weight: 400;
  font-size: 20px;
  line-height: 35px;
}

@media (max-width: 800px) {
  .pressEnter {
    display: none;
  }
}

@media (max-width: 550px) {
  .phoneInput {
    font-size: 16px;
  }

  .countrySelect {
    width: 35%;
  }
}

@media (max-width: 480px) {
  .mainuiImg {
    width: 75%;
  }

  .congratsTxt {
    margin-top: -3rem;
  }
}

@media (max-width: 400px) {
  .selectBtnWrapper {
    background: #527fa9;
    border-radius: 0px 10px 10px 0px;
    height: 55px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .selectBtnWrapper svg {
    width: 15px;
  }

  .selectedTxt {
    padding: 10px;
    font-size: 16px;
  }
}