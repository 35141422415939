@font-face {
  font-family: 'Phenomicon Laser';
  src: url('../../assets/fonts/phenomiconlaser.ttf');
}

.background {
  background: linear-gradient(180deg, #002339 -19.53%, #1D0B1D 122.66%) !important;
  min-height: 100vh;
}

.container {
  --max-width: 1500px;
  --container-padding: 1rem;
  max-width: min(var(--max-width), 100%-2*var(--container-padding));
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 40px;
}

.flexContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flexItem1 {
  padding: 8rem 0 8rem 5rem;
}

.flexItem1 p {
  max-width: 45ch;
  font-size: 20px;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.flexItem1 h1 {
  font-size: 64px;
  font-weight: 700;
}

.flexItem1 h1 span {
  background: linear-gradient(90.06deg, #8E8BD5 1.07%, #8E8BD5 99.95%),
    linear-gradient(0deg, #FFFFFF, #FFFFFF);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;

}

.flexItem2 {
  width: max-content;
}

.flexItem2 img {
  width: 80%;
  margin: auto;
  object-fit: cover;
}

.button {
  background: #8E8BD5;
  border-radius: 10px;
  padding: 1rem 2rem;
  font-weight: 600;
  font-size: 24px;
  text-decoration: none;
  border: none;
  color: white !important;
  cursor: pointer;
}

@media (max-width: 50em) {
  .flexContainer {
    flex-direction: column;
    align-items: center;
  }

  .flexItem1 {
    padding: 2rem 0 2rem 1rem;
  }

  .flexItem1 h1 {
    font-size: 48px;
  }

  .flexItem1 p {
    max-width: 30ch;
    font-size: 16px;
    margin-top: 2rem;
    margin-bottom: 2rem;
    text-align: center;
  }

  .button {
    padding: 1rem 2rem;
    font-size: 16px;
    transform: translateX(65px);
  }

  .flexItem2 img {
    width: 50%;
    margin: auto;
    object-fit: cover;
  }
}

.arrowDown {
  padding-bottom: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.arrowDown img {
  width: 70px;
  height: 70px;
  animation: bounce 2s infinite;
}

@keyframes bounce {

  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-30px);
  }

  60% {
    transform: translateY(-15px);
  }
}


.projectHeader {
  color: var(--headerColor);
  max-width: 45ch;
}

.index {
  font-family: 'Phenomicon Laser';
  font-size: 96px;
}

.projectName {
  font-style: italic;
  font-weight: 600;
  font-size: 36px;
}

.connectToProject {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.connectToProject a {
  color: var(--textColor);
  font-weight: 700;
  font-size: 20px;
}

.projectItem1 {
  padding: 8rem 0 max(20rem, 30vh) 0;
  perspective: 10px;
}

.projectItem1 p {
  color: var(--textColor);
  max-width: 40ch;
  font-size: 16px;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

@media (max-width: 50em) {
  .index {
    font-size: 60px;
  }

  .projectName {
    font-size: 24px;
  }

  .connectToProject {
    display: flex;
    gap: 1rem;
    align-items: center;
  }

  .projectItem1 {
    padding: 2rem 1rem 2rem 2rem;
  }
}

/* Nav styling */

.primaryHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.25em 0;
  display: grid;
  place-items: center;
  font-family: 'Poppins';
  background: transparent;
  width: 90%;
  margin: auto;
  margin-top: 1rem;
  position: sticky;
  top: 30px;
  z-index: 10;
}

@media (max-width: 50em) {
  .primaryHeader {
    margin-top: auto;
  }
}

.primaryNavigation {
  display: flex;
  flex-grow: 1;
  align-items: center;
}

.navWrapper {
  padding: 0.75em 4em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 30px;
  box-shadow: 0px 16px 40px rgba(0, 0, 0, 0.15);
  border-radius: 44px;
}

.mobileNavToggle {
  display: none;
}

.mobileVisible {
  display: none;
}

.navList {
  font-family: "Poppins";
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  gap: 1rem;
  margin-left: auto;
  margin-right: auto;
  color: #000000;
}


.iconClose,
.iconHamburger {
  color: white;
  z-index: 10;
}

.toggleDisplay {
  display: none;
}

.noStyle {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.btnStyle {
  background: #0000001f;
  border-radius: 8px;
  border: none;
  outline: inherit;
  color: white !important;
  padding: 0.5rem 1rem;
}

.visuallyHidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  /* added line */
  border: 0;
}

@media (max-width: 50em) {
  .navList {
    max-width: 40vh;
    font-size: 16px;
  }

  .btnStyle {
    padding: 0.25rem 0.5rem;
  }

  .navWrapper {
    overflow-x: scroll !important;
    padding: 0.5em 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 16px 40px rgba(0, 0, 0, 0.15);
    border-radius: 44px;
    max-width: 45vh;
  }
}

.animateBackground {
  transition: background-color 0.5s ease-out;
  background-size: 100vh;
}

/* .projectContent{
  position: relative;
 } */
.inner {
  position: relative;
}

.image {
  position: fixed;
  width: 30%;
  top: 50%;
  right: 100px;
  padding-bottom: 40px;
  transform: translateY(-50%);
  animation: zoomIn 0.25s ease-in-out;
}

.image0 {
  position: absolute;
  width: 30%;
  top: 65vh;
  right: 100px;
  transform: translateY(-50%);
  animation: zoomIn 0.25s ease-in-out;
}

.image30 {
  position: absolute;
  width: 30%;
  bottom: 60px;
  right: 100px;
  transform: translateY(-50%);
  animation: zoomIn 0.25s ease-in-out;
}

@keyframes zoomIn {
  0% {
    transform: translateY(-50%) scale(0.5);
  }

  100% {
    transform: translateY(-50%) scale(1);
  }
}

.image img {
  max-height: max(350px, 50vh);
  max-width: 400px;
  margin: auto;
}

.image30 img {
  max-height: max(350px, 50vh);
  max-width: 400px;
  margin: auto;
}

.image0 img {
  max-height: max(350px, 50vh);
  max-width: 400px;
  margin: auto;
}

.displayNone {
  display: none;
}

@media (min-width: 50em) {
  .mobileView {
    display: none;
  }

  .desktopView {
    display: block;
  }
}

@media (max-width: 50em) {
  .mobileView {
    display: block;
  }

  .desktopView {
    display: none;
  }

}

.flexContainer1 {
  display: flex;
  flex-direction: column;
}

.image1 img {
  width: min(80%, 500px);
  margin: auto;
}

.projectContact {
  position: relative;
}

.projectContact::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: min(200px, 20vw);
  height: min(70%, 50vh);
  background-image: url('../../assets/images/projectsContactBackground1.png');
  background-repeat: no-repeat;
}

.projectContact::after {
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  width: min(200px, 10vw);
  height: min(60%, 40vh);
  background-image: url('../../assets/images/projectsContactBackground2.png');
  background-repeat: no-repeat;
}

@media (max-width: 768px) {

  .projectContact::after,
  .projectContact::before {
    display: none;
  }
}

.centerElements {
  padding: 7rem 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3rem;
}

.centerElements h1 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 60px;
  text-align: center;
  color: #8E8BD5;
  max-width: 35ch;
}

.centerElements h2 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 40px;
  text-align: center;
  color: rgba(255, 255, 255, 0.7);
}

.getaquotaBtn {
  padding: 2px 50px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 48px;
  cursor: pointer;
  border-radius: 10px;
  text-align: center;
  transition: 300ms background-position ease-in-out;
  margin-bottom: 20px;
  width: fit-content;
  cursor: pointer;
  text-align: center;
  position: relative;
  overflow: hidden;
  border: none;
  z-index: 1;
}


@media(max-width:850px) {
  .centerElements {
    padding: 7rem 1rem;

  }

  .centerElements h1 {
    font-size: 30px;
    line-height: 36px;

  }
}

.getaquotaBtn:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #8E8BD5;
  border-radius: 10px;
  z-index: -2;
}

.getaquotaBtn:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background-color: #fff;
  transition: all 0.3s;
  border-radius: 10px;
  z-index: -1;
}

.getaquotaBtn:hover {
  color: #000;
}

.getaquotaBtn:hover:before {
  width: 100%;
}

.projectItem1 button {
  all: unset;
  cursor: pointer;
  transform: rotate(45deg);
}