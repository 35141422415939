.lottie{
    width: 300px;
    height: 300px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    background: linear-gradient(180deg, #041422 -19.53%, #150B1D 122.66%);
    border-radius: 10px;
    color: white;
    padding: 20px;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.lottie > div{
    width: 100%;
    height: 70%;
}