.borderText {
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: var(--border-font-stroke);
  color: var(--border-font-fill);
}

.primaryText {
  color: var(--primary-font-color);
}

.secondaryText {
  color: var(--secondary-font-color);
}

.card {
  background-color: var(--card-bg-transparent);
}

.image {
  filter: var(--img-filter-full);
  color: white;
  font-size: 40px !important;
}
