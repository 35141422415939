.whyUsMainLead {
  margin-top: 1rem;
  margin-bottom: 7rem;
}

.whyUsLeadHeading {
  font-family: var(--lh-clash);
  font-style: normal;
  font-weight: bold;
  font-size: 52px;
  line-height: 78px;
  color: #ffffff;
  text-align: center;
}

.whyUsLeadHeading span {
  background: linear-gradient(90.06deg, #8E8BD5 1.07%, #8E8BD5 99.95%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.whyUsLeadContentWrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  gap: 1rem;
  width: 80%;
  margin: auto;
  margin-top: 6rem;
}

.whyUsLeadContent {
  display: flex;
  padding: 2rem;
  align-items: center;
  gap: 1.8125rem;
  border-radius: 1rem;
  background: rgba(217, 217, 217, 0.1);
}

.whyUsInfo {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.whuUsInfoHeading {
  color: #8E8BD5;
  font-family: var(--lh-jakartaSans);
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.whuUsInfoTest {
  color: rgba(255, 255, 255, 0.8);
  font-family: Poppins;
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@media only screen and (max-width: 1024px) {}

@media only screen and (max-width: 850px) {
  .whyUsLeadHeading {
    font-size: 30px;
    line-height: 36px;
  }

  .whyUsLeadContentWrapper {
    grid-template-columns: repeat(1, 1fr);
    margin-top: 4rem;
  }
}

@media only screen and (max-width: 550px) {
  .whuUsInfoHeading {
    font-size: 1.1rem;
  }

  .whuUsInfoTest {
    font-size: 0.9rem;
  }

  .whyUsLeadContentWrapper {
    margin-top: 3rem;
    width: 90%;
    gap: 1rem;
  }

  .whyUsLeadContent {
    padding: 1rem;
    gap: 0.5rem;
  }

  .whyUsIcon {
    width: 3rem;
  }
}

@media only screen and (max-width: 425px) {}